<template>
  <main class="main-landig">
    <section class="section1">
      <div class="center">
        <h1>
          강력한 퍼스널 브랜딩의 시작,<br />
          나만의 홈 단 10초.
        </h1>
        <p>
          이 시대 가장 트렌디한 창작자, <br class="mo" />아티스트, 전문가가
          선택한 빅크.<br />
          내 소개, SNS 링크, 커뮤니티로 <br class="mo" />나만의 포트폴리오를
          만드세요.
        </p>
        <div class="alias-form">
          <div>
            <span class="deco-font default-text">bigc.im/</span>
            <input
              type="text"
              placeholder="yourname"
              :value="state.alias.creator"
              @input="actions.updateAliasCreator($event)"
            />
          </div>
          <button
            @click="actions.loginByAuthService('order1', state.alias.creator)"
          >
            브랜드 홈 무료 체험하기
          </button>
        </div>
      </div>
      <div class="pc video-wrapper">
        <video
          autoplay
          loop
          muted
          playsinline
          width="1060"
          :poster="`/assets/web_image/studio-landing/contents/landing_motion_creators.${state.posterExtension}`"
        >
          <source
            src="https://cdn.bigc.im/statics/studio/landing/landing-motion-creators.webm"
            type="video/webm"
          />
          <source
            src="https://cdn.bigc.im/statics/studio/landing/landing-motion-creators.mp4"
            type="video/mp4"
          />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
      <div class="mo video-wrapper">
        <video
          autoplay
          loop
          muted
          playsinline
          :poster="`/assets/web_image/studio-landing/contents/landing_motion_creators_m.${state.posterExtension}`"
        >
          <source
            src="https://cdn.bigc.im/statics/studio/landing/landing-motion-creators-m.webm"
            type="video/webm"
          />
          <source
            src="https://cdn.bigc.im/statics/studio/landing/landing-motion-creators-m.mp4"
            type="video/mp4"
          />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
      <div class="web">
        <swiper
          class="flip-card none-scroll"
          :modules="modules"
          :looped-slides="7"
          :autoplay="{
            delay: 0,
            disableOnInteraction: false,
          }"
          :free-mode="{
            enabled: true,
          }"
          :width="100"
          :space-between="10"
          :loop="true"
          :slides-per-view="1"
          :speed="6000"
          :breakpoints="{
            1090: { width: 150, spaceBetween: 20 },
          }"
        >
          <swiper-slide v-for="creator in brandHomeCreators" :key="creator.id">
            <a
              :href="`https://bigc.im/${creator.name}`"
              target="_blank"
              @click="
                actions.creatorDataLayerEvent(`https://bigc.im/${creator.name}`)
              "
            >
              <div class="flip-inner">
                <div>
                  <image-component
                    image-path="/assets/web_image/studio-landing/creator"
                    extension="png"
                    :file-name="`card_front_${creator.name}`"
                    :alt="creator.title"
                  ></image-component>
                </div>
                <div>
                  <image-component
                    image-path="/assets/web_image/studio-landing/creator"
                    extension="png"
                    :file-name="`card_back_${creator.name}`"
                    :alt="creator.name"
                  ></image-component>
                </div>
              </div>
            </a>
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <div style="background-color: #ffffff">
      <section ref="creatorAd" class="section3">
        <div class="center">
          <h2>
            누구나 무료로 가능합니다. <br class="mo" />브랜드 홈에서 팬과 수익
            <br class="mo" />모두 얻으세요.
          </h2>
          <ul class="ps-0">
            <li class="big-icon doller">
              <h4>약 <span>1.5</span> 억</h4>
              <p>세션 1회 최고 매출</p>
              <small>*기간 : 2022년 2월~23년 3월</small>
            </li>
            <li class="big-icon money">
              <h4><span>7,800</span> 만원</h4>
              <p>상위 20% 평균 매출</p>
              <small>*기간 : 2022년 2월~23년 3월</small>
            </li>
            <li class="big-icon smile">
              <h4><span>1,530</span> 명</h4>
              <p>상위 20% 세션별 평균 구매</p>
              <small>*기간 : 2022년 2월~23년 3월</small>
            </li>
          </ul>
        </div>

        <div class="web">
          <swiper
            class="creator-swiper creator slide"
            :modules="section3Modules"
            :navigation="true"
            :loop="true"
            :speed="500"
            :slides-per-view="1"
            @swiper="actions.onSwiperSection3"
            @slideNextTransitionStart="actions.onSlideNextSection3"
            @slidePrevTransitionStart="actions.onSlidePrevSection3"
          >
            <swiper-slide>
              <div class="img-wrapper">
                <div class="img-creator">
                  <image-component
                    image-path="/assets/web_image/studio-landing/contents"
                    file-name="creator_lsa"
                    alt="이슬아 작가"
                  ></image-component>
                </div>
              </div>
              <div class="text-wrapper">
                <small>Writer</small>
                <h3>이슬아 작가</h3>
                <p>
                  오프라인 강연을 훨씬 선호하지만, 공들여<br />
                  준비하는 팀과 함께라면 뭔가 특별한 라이브가<br />
                  될 것 같아 호기심이 생겼습니다.
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="img-wrapper">
                <div class="img-creator">
                  <image-component
                    image-path="/assets/web_image/studio-landing/contents"
                    file-name="creator_lyh"
                    alt="이윤희 작가"
                  ></image-component>
                </div>
              </div>
              <div class="text-wrapper">
                <small>Webtoon Artist</small>
                <h3>이윤희 작가</h3>
                <p>
                  나 혼자서는 하기 힘들겠다 하는 생각을<br />했거든요. 덕분에
                  독자님들과 즐거운 시간<br />보낼 수 있어 감사하고 좋았습니다.
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="img-wrapper">
                <div class="img-creator">
                  <image-component
                    image-path="/assets/web_image/studio-landing/contents"
                    file-name="creator_alex"
                    alt="머니랩 알렉스 크리에이터"
                  ></image-component>
                </div>
              </div>
              <div class="text-wrapper">
                <small>Value-Add Fund General Partner</small>
                <h3>알렉스 머니랩 파운더</h3>
                <p>
                  빅크를 이용하고 커뮤니티 멤버분들과<br />지속적인 소통이
                  가능했던 게 가장 좋았어요.
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="img-wrapper">
                <div class="img-creator">
                  <image-component
                    image-path="/assets/web_image/studio-landing/contents"
                    file-name="creator_kni"
                    alt="김나이 크리에이터"
                  >
                  </image-component>
                </div>
              </div>
              <div class="text-wrapper">
                <small>Career Accelerator</small>
                <h3>김나이 커리어 엑셀러레이터</h3>
                <p>
                  VOD처럼 편집된 것 보다 원래의 저를<br />드러내는 걸 좋아해서
                  라이브가 더 편했어요.
                </p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </section>
      <section class="section4">
        <div class="center">
          <h2 class="title">
            어떤 기능으로 나를 표현할까요?<br />
            모든 시작은 무료 입니다.
          </h2>
          <p class="desc">
            스튜디오 홈 설정에서 SNS 채널과 팔로워를 모아 <br class="mo" />한
            번에 관리할수 있어요.<br />
            이제 더 이상 나에 대한 자세한 설명은 생략해도 됩니다.
          </p>
        </div>
        <div class="pc video-wrapper">
          <video
            autoplay
            loop
            muted
            playsinline
            width="1060"
            :poster="`/assets/web_image/studio-landing/contents/landing_motion_widget.${state.posterExtension}`"
          >
            <source
              src="https://cdn.bigc.im/statics/studio/landing/landing-motion-widget.webm"
              type="video/webm"
            />
            <source
              src="https://cdn.bigc.im/statics/studio/landing/landing-motion-widget.mp4"
              type="video/mp4"
            />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
        <div class="mo video-wrapper">
          <video
            autoplay
            loop
            muted
            playsinline
            :poster="`/assets/web_image/studio-landing/contents/landing_motion_widget_m.${state.posterExtension}`"
          >
            <source
              src="https://cdn.bigc.im/statics/studio/landing/landing-motion-widget-m.webm"
              type="video/webm"
            />
            <source
              src="https://cdn.bigc.im/statics/studio/landing/landing-motion-widget-m.mp4"
              type="video/mp4"
            />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      </section>
      <section class="section5">
        <div class="center">
          <h2 class="title">
            빅크 스튜디오.<br />
            나만의 플랫폼, 강력한 마케팅 툴
          </h2>
          <div>
            <h3 class="sub-title">
              <span class="deco-font">대시보드∙멤버 운영</span>
            </h3>
            <p class="desc">
              오늘은 홈에 누가 얼마나 <br class="mo" />방문했을까요?<br />
              수익 통계와 멤버 관리 분석까지
            </p>
            <div class="img-wrapper">
              <image-component
                class="pc"
                image-path="/assets/web_image/studio-landing/contents"
                file-name="tool_dashboard"
                alt="대시보드,멤버 페이지 이미지"
              ></image-component>

              <image-component
                class="mo"
                image-path="/assets/web_image/studio-landing/contents"
                file-name="tool_dashboard_m"
                alt="대시보드,멤버 기능 소개"
              ></image-component>
            </div>
          </div>
          <div>
            <h3 class="sub-title">
              <span class="deco-font">콘텐츠 판매∙정산</span>
            </h3>
            <p class="desc">
              나만의 플랫폼에서 <br class="mo" />꾸준한 수익을 만드세요<br />
              VOD, 라이브 유료 티켓을 <br class="mo" />판매해 보세요
            </p>
            <div class="img-wrapper">
              <image-component
                class="pc"
                image-path="/assets/web_image/studio-landing/contents"
                file-name="tool_contents"
                alt="콘텐츠 기능 소개"
              ></image-component>

              <image-component
                class="mo"
                image-path="/assets/web_image/studio-landing/contents"
                file-name="tool_contents_m"
                alt="콘텐츠 기능 소개"
              ></image-component>
            </div>
          </div>
          <div>
            <h3 class="sub-title"><span class="deco-font">커뮤니티</span></h3>
            <p class="desc">
              흩어져 있던 멤버도 <br class="mo" />이제 나만의 홈,<br />
              한곳에서 소통하세요
            </p>
            <div class="img-wrapper">
              <image-component
                class="pc"
                image-path="/assets/web_image/studio-landing/contents"
                file-name="tool_community"
                alt="커뮤니티 기능 소개"
              ></image-component>

              <image-component
                class="mo"
                image-path="/assets/web_image/studio-landing/contents"
                file-name="tool_community_m"
                alt="커뮤니티 기능 소개"
              ></image-component>
            </div>
          </div>
        </div>
      </section>
      <section class="section8">
        <div class="center">
          <h1>비교해볼 수록<br /><span>빅크</span>에 끌릴 수 밖에 없어요-</h1>
          <p>
            빅크에서 크리에이터로 시작해야 하는 명확한 이유,
            <br class="mo" />한 눈에 비교해보고 결정하세요. 더욱 분명해집니다.
          </p>
          <div class="img-wrapper">
            <image-component
              class="pc"
              image-path="/assets/web_image/studio-landing/contents"
              extension="png"
              file-name="platform_table"
              alt="타사 비교 이미지"
            ></image-component>

            <image-component
              class="mo"
              image-path="/assets/web_image/studio-landing/contents"
              extension="png"
              file-name="platform_table_m"
              alt="타사 비교 이미지"
            ></image-component>
          </div>
        </div>
      </section>

      <div class="floating-btn-wrapper">
        <button
          class="floating-btn"
          @click="actions.loginByAuthService('floating')"
        >
          빅크 스튜디오 무료 체험하기
        </button>
      </div>
    </div>
    <section class="section8-1">
      <div class="center">
        <h2>크리에이터를 위한 <br class="mo" />빅크의 다양한 지원</h2>
        <ul class="ps-0">
          <li>
            <h4>다양한 디지털<br />마케팅 지원</h4>
            <p>빅크 SNS 채널 홍보, PR,<br />온라인 광고 등 지원</p>
          </li>
          <li>
            <h4>크리에이터<br />브랜딩 지원</h4>
            <p>
              소개 페이지, 인터뷰 영상, PR 등<br />크리에이터를 위한 브랜딩 지원
            </p>
          </li>
          <li>
            <h4>크리에이터<br />전담 PM 및 PD 지원</h4>
            <p>크리에이터 케어 및<br />콘텐츠 컨셉 및 주제 기획 등 지원</p>
          </li>
          <li>
            <h4>촬영 스튜디오 및<br />장비 지원</h4>
            <p>콘텐츠 제작에 필요한<br />다양한 인적, 물적 자원 지원</p>
          </li>
          <li>
            <h4>광고주 매칭 및<br />다양한 수익화 지원</h4>
            <p>
              추가적인 수익이 날 수 있도록<br />광고주 매칭, 굿즈 제작 등 지원
            </p>
          </li>
        </ul>
      </div>
    </section>
    <section
      class="section9"
      :class="{ 'supported-webp': state.imageExtension === 'webp' }"
    >
      <swiper
        :modules="modules"
        :looped-slides="5"
        :autoplay="{
          delay: 0,
          disableOnInteraction: false,
        }"
        :free-mode="{
          enabled: true,
        }"
        :width="280"
        :space-between="20"
        :loop="true"
        :slides-per-view="1"
        :speed="6000"
      >
        <swiper-slide
          v-for="(item, index) in reviews"
          :key="index"
          class="review-card-wrapper"
        >
          <div class="review-card">
            <div
              class="review-avatar"
              :style="`background-image: url(${item.img})`"
            ></div>
            <h2 v-html="item.title"></h2>
            <p>{{ item.desc }}</p>
          </div>
        </swiper-slide>
      </swiper>
    </section>
  </main>
</template>

<script>
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import helper from "@/helper";
import Cookies from "js-cookie";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/modules/autoplay/autoplay.scss";
import "swiper/modules/free-mode/free-mode.scss";
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/effect-fade/effect-fade.scss";
import { Autoplay, FreeMode, Navigation } from "swiper";
import DatalayerEvents from "@/helper/datalayerEvents";
import ImageComponent from "../../../components/web/ImageComponent/ImageComponent";
import { webpIsSupported } from "@/helper/landing";

export default {
  name: "StudioLanding",
  components: {
    ImageComponent,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const creatorAd = ref();

    const dataLayerEvents = new DatalayerEvents();

    const state = reactive({
      isAuth: computed(() => {
        return store.getters["auth/token"];
      }),
      //  footer
      swiperSection3: null,
      alias: {
        creator: "",
        brandHome: "",
      },
      imageExtension: "webp",
      posterExtension: "webp",
    });

    onBeforeMount(() => {
      state.imageExtension = webpIsSupported() ? "webp" : "png";
      state.posterExtension = webpIsSupported() ? "webp" : "jpg";
    });

    onMounted(() => {
      initCss();
    });

    onBeforeUnmount(() => {
      resetCss();
    });

    const brandHomeCreators = [
      {
        id: 1,
        name: "bigcon",
        title: "크리에이터 이코노미 트렌더 리더들의 공간",
      },
      {
        id: 2,
        name: "leesulla",
        title: "일간 이슬아",
      },
      {
        id: 3,
        name: "smilekdy7",
        title: "김작가 TV",
      },
      {
        id: 4,
        name: "youha",
        title: "러브라이터 YOUHA",
      },
      {
        id: 5,
        name: "leeyeon_studio",
        title: "삶을 그리는 작가 이연",
      },
      {
        id: 6,
        name: "brandboy",
        title: "팔리는 브랜드 전도사 브랜드 보이",
      },
      {
        id: 7,
        name: "kelly",
        title: "MZ ICON 유빈 KELLY",
      },
      {
        id: 8,
        name: "hyojinchoi",
        title: "국민언이 효진초이",
      },
      {
        id: 9,
        name: "yoonhee_lee",
        title: "사랑과 개그가 듬뿍담긴 그림 이윤희",
      },
      {
        id: 10,
        name: "korealaywer",
        title: "인생선배의 지혜전수 아는 변호사",
      },
      {
        id: 11,
        name: "aiki",
        title: "월클댄서 아이키",
      },
      {
        id: 12,
        name: "zzanggem",
        title: "가장 맛있는 콘텐츠 정육왕",
      },
      {
        id: 13,
        name: "sejin",
        title: "바로 영어 with 세진쌤",
      },
      {
        id: 14,
        name: "aftermoment",
        title: "글쓰는 디자이너 박창선",
      },
      {
        id: 15,
        name: "yogaboy",
        title: "당신의 바른몸 요가소년",
      },
      {
        id: 16,
        name: "iam_malbus",
        title: "말을 잘 하고 시다면 말버스",
      },
      {
        id: 17,
        name: "moneylab",
        title: "밀레니얼의 경제적 자유를 위한 실전투자 커뮤니티",
      },
      {
        id: 18,
        name: "niceulsan",
        title: "비트코인 전설 홍지윤",
      },
      {
        id: 19,
        name: "gunu_family",
        title: "인생 제2막 거누파파네",
      },
      {
        id: 20,
        name: "heeaeson_way",
        title: "개념있는 희애씨",
      },
      {
        id: 21,
        name: "octoberfilm",
        title: "인생을 여행처럼 시월필름",
      },
      {
        id: 22,
        name: "winefoodbalance",
        title: "와인 · 푸드 · 밸런스 와푸밸",
      },
    ];

    const reviews = [
      {
        title: `<span>라이브 이후로 <br/>언니랑
가까워진 게 느껴져요.</span><br/>
라이브로 자주 만나면서<br/>
더 돈독해지고 싶어요.`,
        desc: "아이키 클럽 구매자",
        img: `/assets/web_image/studio-landing/contents/review_user_1.${state.imageExtension}`,
      },
      {
        title: `<span>실시간으로 질문에 대한<br/>
답변</span>을 들을 수 있어서 좋아요.<br/>
제대로 강의를 듣고 있다는<br/>
느낌이 들더라구요.`,
        desc: "아는변호사 클럽 구매자",
        img: `/assets/web_image/studio-landing/contents/review_user_2.${state.imageExtension}`,
      },
      {
        title: `평소에 쉽게 접할 수 없는<br/>
주제에 대하여<br/>
<span>마음 맞는 사람들끼리</span><br/>
소통할 수 있어 유익해요!`,
        desc: "류세라 클럽 구매자",
        img: `/assets/web_image/studio-landing/contents/review_user_3.${state.imageExtension}`,
      },
      {
        title: `<span>실시간으로 팬들과<br/>
굿즈 디자인</span>을 하다니요?<br/>
작가님과 같이 만든 굿즈<br/>
희소한 굿즈가 생겨 행복해요.`,
        desc: "이윤희 클럽 구매자",
        img: `/assets/web_image/studio-landing/contents/review_user_4.${state.imageExtension}`,
      },
      {
        title: `강의 자체도 유익한데<br/>
<span>실시간 Q&A </span>까지 더해지니<br/>
<span>공감백배,</span> ‘정말 알차다'라는<br/>
생각이 절로 들었습니다!`,
        desc: "이슬아 클럽 구매자",
        img: `/assets/web_image/studio-landing/contents/review_user_5.${state.imageExtension}`,
      },
      {
        title: `다양한 연사분들을 한 자리에서<br/>
만날 수 있다보니 유익해요.<br/>
여기서 강의 들으면 <span>중도포기<br/>
하게 될 일이 없어요.</span>`,
        desc: "빅콘 NFT 클럽 구매자",
        img: `/assets/web_image/studio-landing/contents/review_user_6.${state.imageExtension}`,
      },
      {
        title: `
        새로운 일에 도전할 때<br/>
        아무래도 혼자서 막막하잖아요.<br/>
        그 삶을 이미 살아본 <span>크리에이터의<br/>
        노하우</span>를 들을 수 있어 좋아요.
        `,
        desc: "빅콘 IP 클럽 구매자",
        img: `/assets/web_image/studio-landing/contents/review_user_7.${state.imageExtension}`,
      },
      {
        title: `라이브 중간중간 글쓰기에 대한<br/>
<span>작가님의 가치관</span>을 볼 수 있어서<br/>
제 안의 글쓰기 욕구와 열정이<br/>
마구마구 솟구칩니다!`,
        desc: "이슬아 클럽 구매자",
        img: `/assets/web_image/studio-landing/contents/review_user_8.${state.imageExtension}`,
      },
    ];

    const initCss = () => {
      const html = document.getElementsByTagName("html")[0].style;
      const app = document.getElementById("app").style;
      const body = document.body.style;
      html.position = "static";
      html.overflowY = "visible";
      body.position = "static";
      body.overflow = "visible";
      body.height = "auto";
      app.overflowX = "visible";
      app.overflowY = "visible";
    };

    const resetCss = () => {
      const html = document.getElementsByTagName("html")[0].style;
      const app = document.getElementById("app").style;
      const body = document.body.style;
      html.position = "fixed";
      html.overflowY = "hidden";
      body.position = "fixed";
      body.overflow = "hidden";
      body.height = "100%";
      app.overflowY = "scroll";
    };

    const actions = {
      goToStudio: (data, alias = "") => {
        if (data) {
          let eventObj = dataLayerEvents[data];
          dataLayerEvents.push(eventObj);
        }

        if (alias) {
          let date = new Date();
          date.setTime(date.getTime() + 30 * 60 * 1000);

          Cookies.set("onBoardingAlias", alias, {
            expires: date,
          });
        }

        let routeName = state.isAuth ? "console.home" : "auth.login";
        router.push({ name: routeName });
      },
      loginByAuthService: (data, alias = "") => {
        if (data) {
          let eventObj = dataLayerEvents[data];
          dataLayerEvents.push(eventObj);
        }

        if (alias) {
          let date = new Date();
          date.setTime(date.getTime() + 30 * 60 * 1000);

          Cookies.set("onBoardingAlias", alias, {
            expires: date,
          });
        }

        if (state.isAuth) {
          router.push({ name: "console.home" });
        } else {
          const authUrl = process.env.VUE_APP_AUTH_DOMAIN;
          const redirectUri = `${process.env.VUE_APP_URL}/console`;
          window.location.href =
            authUrl +
            "/auth/login?" +
            "redirect=" +
            encodeURIComponent(redirectUri) +
            "&brand=bigcstudio";
        }
      },
      updateAliasCreator: (e) => {
        state.alias.creator = e.target.value;
      },
      creatorDataLayerEvent: (url) => {
        let eventObj = dataLayerEvents.creator(url);
        dataLayerEvents.push(eventObj);
      },
      onSwiperSection3: (swiper) => {
        state.swiperSection3 = swiper;
      },
      onSlideNextSection3: () => {
        // 처음 swiper 인스턴스 생성전에 한번 실행됨. 인스턴스 여부로 data layer event 실행 결정
        if (state.swiperSection3) {
          let eventObj = dataLayerEvents.next;
          dataLayerEvents.push(eventObj);
        }
      },
      onSlidePrevSection3: () => {
        let eventObj = dataLayerEvents.previous;
        dataLayerEvents.push(eventObj);
      },
    };

    return {
      state,
      modules: [Autoplay, FreeMode],
      section3Modules: [Navigation],
      actions,
      creatorAd,
      helper,
      brandHomeCreators,
      reviews,
    };
  },
};
</script>

<style scoped src="./style.css"></style>
